// import {requisiteTypes} from './requisiteTypes';
// requisiteTypes
// sbp/card/account

// account_number phone pan

// - btripsexpenses
// - ios-app-smartonline
// - budgetonline-ios
// - sberbankonline
// - sbolonline

const requisiteTypeMapping = {
    card: 'pan',
    account: 'account_number',
    sbp: 'phone',
  }
  
  export const deepLinks = [
    // intent://ru.sberbankmobile/payments/p2p?type=account_number&requisiteNumber=40820810540101964015
    {
      link: `intent://ru.sberbankmobile/payments/p2p?type={type}&requisiteNumber={requisiteNumber}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account_number',
        card: 'card_number',
        sbp: 'card_number',
      },
      params: {
        type: 'requisiteType',
        requisiteNumber: 'requisite',
        amount: 'amount',
      },
      platforms: ['android'], // ios, android
      requisiteTypes: ['sbp', 'card', 'account'], // sbp, card, account
    },
    //
    {
      link: `btripsexpenses://sbolonline/p2ptransfer?type={type}&to={to}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['ios'],
      requisiteTypes: ['sbp', 'card', 'account'],
    },
    {
      link: `ios-app-smartonline://sbolonline/p2ptransfer?type={type}&to={to}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['ios'],
      requisiteTypes: ['sbp', 'card', 'account'],
    },
    {
      link: `budgetonline-ios://sbolonline/p2ptransfer?type={type}&to={to}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['ios'],
      requisiteTypes: ['sbp', 'card', 'account'],
    },
    {
      link: `sbolonline://p2ptransfer?type={type}&to={to}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['ios'],
      requisiteTypes: ['sbp', 'card', 'account'],
    },
    {
      link: `sberbankonline://p2ptransfer?type={type}&to={to}&amount={amount}`,
      requisiteTypeMapping: {
        account: 'account',
        card: 'card',
        sbp: 'card',
      },
      params: {
        type: 'requisiteType',
        to: 'requisite',
        amount: 'amount',
      },
      platforms: ['ios'],
      requisiteTypes: ['sbp', 'card', 'account'],
    },
  
    // sberbankonline
    // sberbankonline://p2ptransfer?type=account&to=40820810540101964015&amount=1000
    // sbolonline
    // sbolonline://p2ptransfer?type=account&to=40820810540101964015&amount=1000
    // budgetonline
    // budgetonline-ios://sbolonline/p2ptransfer?type=account&to=40820810540101964015&amount=1000
    // iosappsmartonline
    // ios-app-smartonline://sbolonline/p2ptransfer?type=account&to=40820810540101964015&amount=1000
    // btripsexpenses
    // btripsexpenses://sbolonline/p2ptransfer?type=account&to=40820810540101964015&amount=1000
  ]
  
  export function getDeepLinks(platform, requisiteType, params) {
    const filteredLinks = deepLinks.filter((link) => {
      if(link.platforms.includes(platform) && link.requisiteTypes.includes(requisiteType)) {
        return true
      }
    })
  
    filteredLinks.forEach(link => {
      const deepLinkParamsValues = {
        ...params,
        requisite: params[requisiteTypeMapping[requisiteType]],
        requisiteType: link.requisiteTypeMapping[requisiteType]
      }
  
      Object.entries(link.params).forEach(([replace,param]) => {
        link.link = link.link.replace(`{${replace}}`, deepLinkParamsValues[param])
      })
    })
  
    return filteredLinks
  }
  