export const ru = {
  common: {
    title: {
      phone: 'Сделайте перевод',
      card: 'Перевод по номеру карты',
      sbp: 'Сделайте перевод по СБП',
      sberPay: 'Сделайте перевод по Sberpay',
      walletm10: 'Сделайте перевод',
      local_banks: 'Сделайте перевод',
      account: '',
    },
    copied: 'Скопирован',
  },
  PageHeader: {
    order: 'Заказ {{order}}',
  },
  BankList: {
    selectBank: 'Выберите метод оплаты',
    selectSourceBank: 'Выберите банк, с которого будете переводить средства',
    bankSelectionNotice: 'Будьте аккуратны с выбором банка. Повторно выбрать банк нельзя.',
  },
  CardForm: {
    amountChangedTitle: 'Внимание!',
    amountChangedSubTitle1: 'Реквизиты на изначальную сумму временно недоступны. Во избежание потери денежных средств оплатите указанную ниже сумму или отмените платёж.',
    amountChangedSubTitle2: 'Вся сумма поступит на ваш баланс.',
    amountChangedText: '{{amount}} {{currency}}',
    amountChangedConfirm: 'Продолжить',
    sbpPhone: 'Номер получателя',
    sbpBank: 'Банк получателя',
    sbpFio: 'ФИО получателя',
    remainingTime: 'Время на перевод:',
    pan: 'Номер карты',
    phone: 'Номер телефона',
    accountNumber: 'Номер счета',
    recipient: 'Получатель',
    uploadTransactionReceipt: 'Загрузите чек операции',
    attention: 'Инструкция',
    paymentConfirmed: 'Проверить статус платежа',
    cancel: 'Отменить',
    selectYourMobileDevice: 'Выберите ваше устройство',
    returnToShop: 'Вернуться на сайт',
    paymentTypes: {
      card: 'Сделайте перевод по номеру карты',
      phone: 'Сделайте перевод по номеру телефона',
      sbp: '',
      walletm10: 'Сделайте перевод через М10 по номеру телефона',
      local_banks: 'Сделайте перевод по номеру счета',
      account: 'Сделайте перевод по номеру счета',
    },
    paymentTips: {
      doNotChangeTransferAmount: 'Не меняйте сумму перевода',
      doNotSpecifyTransferComment: 'Не указывайте комментарий к переводу',
      ensureRecipientNameAndBankMatchDetails: 'Убедитесь, что ФИО получателя и банк соответствуют выданным реквизитам',
      paymentMustBeMadeInOneAmount: 'Платеж должен быть совершен одной суммой',
      pressCancelToSelectAnotherBank: 'Для выбора другого банка нажмите “Отменить” и создайте платеж заново.',
      transferToAccountNumberAbove: 'Выполните перевод на номер счета, указанный выше',
      ifUnableToPayPressCancel: 'Если не смогли оплатить, нажмите кнопку «Отменить»',
      enterPaymentAmountAndSubmit: 'В приложении укажите сумму платежа, которую вы указывали при пополнении и нажмите «Отправить»',
      card1: 'Переведите строго указанную сумму',
      card2: 'При переводе проверьте, что номер карты соответствуют указанному выше',
      card3: 'После перевода проверьте статус платежа',
      card4: 'Если не смогли оплатить, нажмите кнопку "Отменить"',
      sberPay1: 'Откройте приложение Сбербанка',
      sberPay2: 'Отсканируйте QR-код',
      sberPay3: 'Укажите точную сумму',
      sberPay4: 'После перевода проверьте статус платежа',
      sberPay5: 'Если не смогли оплатить, нажмите кнопку "Отменить"',
      sberPayMobile1: 'Переведите строго указанную сумму',
      sberPayMobile2: 'После перевода проверьте статус платежа',
      sberPayMobile3: 'Если не смогли оплатить, нажмите кнопку "Отменить"',
      sbp1: 'Переведите строго указанную сумму',
      sbp2: 'При переводе проверьте, что ФИО и Банк получателя соответствуют указанным выше',
      sbp3: 'После перевода проверьте статус платежа',
      sbp4: 'Если не смогли оплатить, нажмите кнопку "Отменить"',
      sberPayDesktop1: 'Отсканируйте QR-код',
      sberPayDesktop2: 'Если не смогли оплатить, нажмите кнопку "Отменить"',
      'sberPay.1': 'Отсканируйте QR-код',
      'sberPay.2': 'Если Банк просит указать назначение платежа - укажите "дар" или "спасибо"',
      'sberPay.3': 'Если не смогли оплатить, нажмите кнопку "Отменить"',
    },
    HowTo: {
      title: 'Инструкция',
      select: 'Выберите тип оплаты',
      // sourceBank: any
      // requisite_type: card, sbp, local_banks, account, phone
      sber_card: {
        1: 'Скопируйте номер карты',
        2: 'Откройте приложение своего банка',
        3: 'Откройте Платежи → В другую страну → {{country_name}} → Карта',
        4: 'Вставьте номер карты и имя получателя ЛАТИНСКИМИ БУКВАМИ',
        5: 'Переведите строго указанную сумму без учёта комиссии',
      },
      sber_sbp: {
        1: 'Скопируйте номер телефона',
        2: 'Откройте приложение своего банка',
        3: 'Откройте Платежи → В другую страну → {{country_name}} → Телефон',
        4: 'Вставьте номер телефона',
        5: 'Переведите строго указанную сумму без учёта комиссии',
      },
      tinkoff_card: {
        1: 'Скопируйте номер карты',
        2: 'Откройте приложение своего банка',
        3: 'Откройте Платежи → По номеру карты',
        4: 'Вставьте номер карты и имя получателя ЛАТИНСКИМИ БУКВАМИ',
        5: 'Переведите строго указанную сумму без учёта комиссии',
      },
      tinkoff_sbp: {
        1: 'Скопируйте номер телефона',
        2: 'Откройте приложение своего банка',
        3: 'Откройте Платежи → Перевод по телефону',
        4: 'Выберите код {{country_code}} ({{country_phone_code}}) и вставьте номер телефона',
        5: 'Переведите строго указанную сумму без учёта комиссии',
      },
      vtb_card: {
        1: 'Скопируйте номер карты',
        2: 'Откройте приложение своего банка',
        3: 'Откройте Платежи → В другую страну → {{country_name}} → По номеру карты',
        4: 'Вставьте номер карты и имя получателя ЛАТИНСКИМИ БУКВАМИ',
        5: 'Переведите строго указанную сумму без учёта комиссии',
      },
      vtb_sbp: {
        1: 'Скопируйте номер телефона',
        2: 'Откройте приложение своего банка',
        3: 'Откройте Платежи → В другую страну → {{country_name}} → По номеру телефона',
        4: 'Вставьте номер телефона',
        5: 'Переведите строго указанную сумму без учёта комиссии',
      }
    }
  },
  CashDeliveryForm: {
    enterCourierData: 'Введите данные для курьера',
    saveConfirmationCode: 'Сохраните код подтверждения',
    confirmationRequiredForCourier: 'Он будет необходим при встрече с курьером',
    confirmationCodePrompt: 'Код подтверждения:',
    attentionWarning: 'Будьте внимательны!',
    courierContactUsageNotice: 'Указанные данные будут использоваться курьером для связи с вами',
    orderAcceptedMessage: 'Спасибо, ваш заказ принят.',
    courierConfirmationMessage: 'Пожалуйста, ожидайте сообщения от курьера для подтверждения.',
    sendCourier: 'Отправить курьера',
    label: {
      messenger: 'Мессенджер',
      phone: 'Номер телефона',
      login: 'логин в мессенджере/номер телефона',
      address: 'Адрес для встречи с курьером',
      immediateDelivery: 'Доставить немедленно',
      deliveryDateTime: 'Дата и время доставки',
    },
    error: {
      required: 'Обязательное поле',
      incorrectNumber: 'Некорректный номер',
      incorrectLogin: 'Некорректный логин',
    },
  },
  StatusBlock: {
    paymentSuccessful: 'Перевод получен',
    paymentDeclined: 'Перевод не найден',
    returnToShop: 'Вернуться на сайт',
    status: {
      somethingWentWrong: 'Что-то пошло не так.',
      waitingForFunds: 'Ожидаем поступления средств.',
      operationCancelled: 'Операция отменена.',
      paymentTimeExpired: 'Время на оплату истекло.',
    },
    return: {
      returnToShopAndRetryPayment: 'Вы можете вернуться на сайт и повторить оплату.',
      returnToShop: 'Вы можете вернуться на сайт.',
    },
  },
  Upload: {
    ok: 'Ок',
    upload: 'Загрузить',
    error: 'Ошибка',
  },
  FatalError: {
    somethingWentWrong: 'Что-то пошло не так.',
    returnToShopAndRetryPayment: 'Вы можете вернуться на сайт и повторить оплату.',
    returnToShop: 'Вернуться на сайт',
    returnToPaymentMethodSelection: 'Вернуться к выбору метода оплаты',
    cancel: 'Отменить',
    code: {
      NO_TERMINALS_AVAILABLE: 'Нет доступных терминалов для данного способа оплаты.',
      FORM_PAY_ALREADY_SUBMITED: 'Пожалуйста, вернитесь в магазин для повторной оплаты.',
      COMMON_ERROR: 'Ошибка при проведении оплаты.',
      DUPLICATE_ORDER_ERROR: 'Заказ с таким номером уже существует.',
      ROUTE_ERROR: 'Отсутствует возможность проведения данного платежа.',
    },
  },
  SberPay: {
    paymentConfirmed: "Я оплатил",
    failedToPay: "Не получилось оплатить",
    remainingTime: 'Время на перевод:',
    HowTo: {
      title: 'Инструкция',
    }
  },
}
