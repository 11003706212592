export const mobileUtils = {

  isAndroidMobile() {
    return /Android/i.test(navigator.userAgent);
  },

  isAppleMobile() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  },

  isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },

  getMobileOS() {
    if(!mobileUtils.isMobile()) {
      return 'desktop'
    }
    if(mobileUtils.isAppleMobile()) {
      return 'ios'
    }
    if(mobileUtils.isAndroidMobile()) {
      return 'android'
    }
    return 'android'
  }
}
