import {
  AlertTitle, Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import React, {useCallback, useState, useEffect, useMemo} from 'react'
import {useTranslation} from "react-i18next";
import {mobileUtils} from '../../utils/mobileUtils';
import {QRCode} from '../QRCode/QRCode';
import {LoadingButton} from '@mui/lab';
import {getDeepLinks} from '../../config/deepLinks';
import {useSelector} from 'react-redux';
import {dispatch} from '../../store/store';
import {formSlice} from '../../store/form/formSlice';
import sberPayLogo from '../../assets/sberPayLogo.svg';
import {getCurrencySymbol} from '../../config/currency';
import {PageHeader} from '../PageHeader/PageHeader';
import {stylesObject} from '../../themes/jpay/components/stylesObject';
import {redirect} from '../../utils/redirect';

export function SberpayForm(props) {
  const {t, i18n} = useTranslation('translation', {keyPrefix: 'CardForm'})
  const {t: ct} = useTranslation('translation', {keyPrefix: 'common'})
  const [isPriceChangedModal, setIsPriceChangedModal] = useState(true)

  function makeHowToList(instruction) {
    const texts = []
    let index = 0
    while (++index) {
      const key = `CardForm.paymentTips.${instruction}${index.toString()}`
      const text = t(`paymentTips.${instruction}${index.toString()}`)
      if (key === text) {
        break
      }
      texts.push(text)
    }
    return texts
  }

  const [desktopInstruction] = useState(makeHowToList('sberPayDesktop'))

  const form = useSelector(store => store.form)
  const {
    params,
    skipBankSelect,
    selectedBank,
    formData,
    formTime,
  } = form
  const [isMobile] = useState(mobileUtils.isMobile())
  const [isDeepLinksProcessing, setIsDeepLinksProcessing] = useState(false)
  const {finish_url} = params

  useEffect(() => {
    if (isMobile && !selectedBank && !skipBankSelect) {
      dispatch(formSlice.actions.fetchBanks())
    }
  }, [isMobile])

  useEffect(() => {
    if (!formData.data && (selectedBank || skipBankSelect)) {
      dispatch(formSlice.actions.fetchData())
    }
  }, [formData.data, selectedBank, skipBankSelect])

  const startProcessing = useCallback(async () => {
    setIsDeepLinksProcessing(true)
    const {beneficiary} = formData?.data
    const links = getDeepLinks(
      mobileUtils.getMobileOS(),
      beneficiary.requisite_type,
      {
        ...beneficiary,
        amount: Number(params.amount)
      },
    )

    localStorage.setItem(`sberpayRedirectData-${params.invoice_id}`, JSON.stringify({
      invoice_id: params.invoice_id,
      isFinished: false,
      isFound: false,
      isConfirmed: false,
      isFailToPay: false,
      currentLinkIndex: 0,
      links,
    }))

    window.location = window.location.href
  }, [formData])

  const timeLimit = formData?.data?.ttl * 60 - (((Date.now() - formTime) / 1e3) | 0)
  const [time, setTime] = useState(timeLimit)
  const timeMin = String(Math.floor(Math.round(time) / 60)).padStart(2, '0')
  const timeSec = String(Math.round(time) % 60).padStart(2, '0')

  useEffect(() => {
    if (!timeLimit) {
      return
    }
    const started = Date.now()
    setTime(timeLimit - (Date.now() - started) / 1e3)
    const interval = setInterval(() => {
      setTime(timeLimit - (Date.now() - started) / 1e3)
    }, 1e3)
    return () => clearInterval(interval)
  }, [timeLimit])

  useEffect(() => {
    if (time <= 0) {
      dispatch(formSlice.actions.formTimeout())
    }
  }, [time, isMobile])

  if (!isMobile) {
    return (
      <div>
        <Typography variant="h5" style={{textAlign: 'center'}}>
          {ct(`title.sberPay`)}
        </Typography>

        <QRCode
          data={window.location.href}
          icon={null}
        />

        {desktopInstruction && (
          <>
            <AlertTitle mb={2}>
              {t('HowTo.title')}
            </AlertTitle>
            <div className={'list'} style={{marginTop: 16}}>
              {desktopInstruction.map((text, index) => (
                <span key={`key-${index}`} data-number={`${index + 1}.`}>
                {text}
              </span>
              ))}
            </div>
          </>
        )}

        <Button
          sx={{mt: 3}}
          variant="outlined"
          onClick={() => dispatch(formSlice.actions.update({status: 'cancelled'}))}
          fullWidth
          size="large"
        >
          {t('cancel')}
        </Button>

        {finish_url && (
          <Button
            sx={{mt: 2.5}}
            fullWidth
            size="large"
            variant="outlined"
            onClick={() => redirect(finish_url)}
          >
            {t('returnToShop')}
          </Button>
        )}
      </div>
    )
  }

  if ((!selectedBank || skipBankSelect) || !formData.data) {
    return (
      <div style={{flexGrow: 1, marginTop: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{
          flexGrow: 1,
          marginTop: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CircularProgress
            size={120}
            thickness={2}
          />
        </div>
      </div>
    )
  }

  let amountRaw = params.amount
  if (formData?.data?.is_amount_changed) {
    amountRaw = formData?.data?.amount
  }
  const amount = new Intl.NumberFormat('ru-Ru').format(amountRaw).replace(',', '.')

  const {banks} = form
  const widgetData = banks?.data?.payment_widget_data ?? {}
  let instructions = widgetData.instructions
  let instruction = makeHowToListMobile('sberPay')

  function makeHowToListMobile(instruction) {
    if (instructions) {
      const texts = []
      let index = 0
      while (++index) {
        if (instructions[`${index}`]) {
          texts.push(instructions[`${index}`])
        }
        if (!instructions[`${index}`] && index) {
          break
        }
      }
      return texts
    }
    const texts = []
    let index = 0
    while (++index) {
      const key = `CardForm.paymentTips.${instruction}.${index.toString()}`
      const text = t(`paymentTips.${instruction}.${index.toString()}`)
      if (key === text) {
        break
      }
      texts.push(text)
    }
    return texts
  }

  return (
    <div>
      {formData?.data?.is_amount_changed && isPriceChangedModal && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            background: '#fff',
            minHeight: '100vh',
            maxWidth: 432,
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            padding: 32,
            boxSizing: "border-box"
          }}
        >
          <Typography mb={0} variant="h5">
            {t('amountChangedTitle')}
          </Typography>

          {t('amountChangedSubTitle1') && (
            <Typography mb={2} variant="subtitle1">
              {t('amountChangedSubTitle1')}
            </Typography>
          )}

          {t('amountChangedSubTitle2') && (
            <Typography mb={2} variant="subtitle1">
              {t('amountChangedSubTitle2')}
            </Typography>
          )}

          <Typography variant="h4">
            {t('amountChangedText', {amount, currency: getCurrencySymbol(params.currency)})}
          </Typography>

          <Button
            variant="contained"
            fullWidth size="large"
            sx={{mb: 2.5, mt: 4}}
            onClick={() => setIsPriceChangedModal(false)}
          >
            {t('amountChangedConfirm')}
          </Button>

          <Button
            variant="outlined"
            fullWidth size="large"
            onClick={() => {
              dispatch(formSlice.actions.update({status: 'cancelled'}))
            }}
          >
            {t('cancel')}
          </Button>
        </div>
      )}

      <div style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h5">
          {ct(`title.sberPay`)}
        </Typography>
      </div>

      <PageHeader/>

      <LoadingButton
        sx={{mb: 2.5, mt: 1.5}}
        className="sberPayBtn"
        loading={isDeepLinksProcessing}
        onClick={startProcessing}
      >
        <div style={{zIndex: 10, display: 'flex', gap: 12}}>
          <div
            style={{
              background: `url("${sberPayLogo}") no-repeat center center`,
              height: 24,
              width: 24,
              backgroundSize: 'contain',
            }}
          />
          <Typography style={{color: '#fff'}}>
            SberPay
          </Typography>
        </div>
      </LoadingButton>

      <Box mt={2} mb={2} style={{color: '#666666'}}>
        {t('remainingTime')}
        {' '}
        <Typography component="span" style={{fontSize: 14, color: '#000'}}>
          {timeMin}:{timeSec}
        </Typography>
      </Box>

      {instruction && (
        <>
          <AlertTitle mb={2}>
            {t('HowTo.title')}
          </AlertTitle>
          <div className={'list'} style={{marginTop: 16}}>
            {instruction.map((text, index) => (
              <span key={`key-${index}`} data-number={`${index + 1}.`}>
                {text}
              </span>
            ))}
          </div>
        </>
      )}

      <Button
        sx={{mt: 3}}
        variant="outlined"
        fullWidth size="large"
        onClick={() => {
          dispatch(formSlice.actions.update({status: 'cancelled'}))
        }}
      >
        {t('cancel')}
      </Button>

    </div>
  )
}
