import React from 'react'
import styles from './requisites.module.scss'
import chip from './chip.png'
import nfc from './nfc.png'
import copy from './copy.svg'
import {useTranslation} from 'react-i18next'
import {IconButton} from '@mui/material'
import {copyToClipboard} from '../../../../utils/copyToClipboard'
import {useSelector} from 'react-redux'

import flagAM from './flags/AM.png'
import flagRU from './flags/RU.png'
import flagKZ from './flags/KZ.png'
import flagKG from './flags/KG.jpeg'
import flagAB from './flags/AB.jpeg'
import flagUZ from './flags/UZ.jpeg'

const flags = {
  AM: flagAM,
  RU: flagRU,
  KZ: flagKZ,
  KG: flagKG,
  AB: flagAB,
  UZ: flagUZ,
}

export function RequisitesCard(props) {
  const {t} = useTranslation('translation', {keyPrefix: 'CardForm'})
  const {type, bankName, cardOwner, value} = props
  const form = useSelector(store => store.form)
  const {banks} = form
  const widgetData = banks?.data?.payment_widget_data ?? {}
  const flag = widgetData?.flag?.toUpperCase() ?? 'AM'

  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
          <div
            className={styles.chip}
            style={{
              backgroundImage: `url("${chip}")`,
            }}
          />

          {type === 'card' ? (
            <div className={styles.bankName}>
              {bankName}
            </div>
          ) : (
            <div className={styles.bankName}>
              {bankName}
            </div>
          )}

          {flag && (
            <div
              className={styles.flag}
              style={{
                backgroundImage: `url("${flags[flag]}")`,
              }}
            />
          )}
          <div
            className={styles.nfc}
            style={{
              backgroundImage: `url("${nfc}")`,
            }}
          />
        </div>

        {type === 'card' && (
          <>
            <div className={styles.label}>
              {t('pan')}
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div className={styles.value}>
                {value.replaceAll(' ', '').match(/.{1,4}/g).map((str, i) => {
                  return <span key={`key-${i}`} style={{marginRight: 8}}>{str}</span>
                })}
              </div>
              <IconButton
                className={styles.copyBtn}
                onClick={() => {
                  copyToClipboard(value)
                }}
              >
                <div
                  className={styles.copyBtnIcon}
                  style={{
                    backgroundImage: `url("${copy}")`,
                  }}
                />
              </IconButton>
            </div>
          </>
        )}
        {type === 'sbp' && (
          <>
            <div className={styles.label}>
              {t('phone')}
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div className={styles.value}>
                {(flag === false || flag === 'false') && (() => {
                  const val = value.replaceAll('+', '')
                  function v(i) {
                    return val[i] ?? ''
                  }
                  return `+${v(0) + v(1) + v(2)} (${v(3) + v(4)}) ${v(5) + v(6) + v(7)} ${v(8) + v(9) + v(10)} ${v(11) + v(12) + v(13)}`
                })()}
                {flag === 'AM' && (() => {
                  const val = value.replaceAll('+', '')
                  function v(i) {
                    return val[i] ?? ''
                  }
                  return `+${v(0) + v(1) + v(2)} (${v(3) + v(4)}) ${v(5) + v(6) + v(7)} ${v(8) + v(9) + v(10)} ${v(11) + v(12) + v(13)}`
                })()}
                {flag === 'RU' && (() => {
                  const val = value.replaceAll('+', '')
                  function v(i) {
                    return val[i] ?? ''
                  }
                  return `+${v(0)} (${v(1) + v(2) + v(3)}) ${v(4) + v(5) + v(6)} ${v(7) + v(8)} ${v(9) + v(10) + v(11)} ${v(12) + v(13)}`
                })()}

                {flag === 'KG' && (() => {
                  const val = value.replaceAll('+', '')
                  function v(i) {
                    return val[i] ?? ''
                  }
                  return `+${v(0) + v(1) + v(2)} (${v(3) + v(4) + v(5)}) ${v(6) + v(7) + v(8)} ${v(9) + v(10) + v(11)}`
                })()}

                {flag === 'UZ' && (() => {
                  const val = value.replaceAll('+', '')
                  function v(i) {
                    return val[i] ?? ''
                  }
                  return `+${v(0) + v(1) + v(2)} (${v(3) + v(4)}) ${v(5) + v(6) + v(7)} ${v(8) + v(9)} ${v(10) + v(11) + v(20)} ${v(13) + v(14)}`
                })()}

                {flag === 'KZ' && (() => {
                  const val = value.replaceAll('+', '')
                  function v(i) {
                    return val[i] ?? ''
                  }
                  return `+${v(0)} (${v(1) + v(2) + v(3)}) ${v(4) + v(5) + v(6)} ${v(7) + v(8)} ${v(9) + v(10) + v(11)} ${v(12) + v(13)}`
                })()}

                {flag === 'AB' && (() => {
                  const val = value.replaceAll('+', '')
                  function v(i) {
                    return val[i] ?? ''
                  }
                  return `+${v(0)} (${v(1) + v(2) + v(3)}) ${v(4) + v(5) + v(6)} ${v(7) + v(8)} ${v(9) + v(10) + v(11)} ${v(12) + v(13)}`
                })()}

                {/*{value.replaceAll(' ', '').match(/.{1,4}/g).map((str, i) => {*/}
                {/*  return <span key={`key-${i}`} style={{marginRight: 8}}>{str}</span>*/}
                {/*})}*/}
              </div>
              <IconButton
                className={styles.copyBtn}
                onClick={() => {
                  copyToClipboard(value)
                }}
              >
                <div
                  className={styles.copyBtnIcon}
                  style={{
                    backgroundImage: `url("${copy}")`,
                  }}
                />
              </IconButton>
            </div>
          </>
        )}
        {type === 'account' && (
          <>
            <div className={styles.label}>
              {t('account')}
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div className={styles.value}>
                {value}
              </div>
              <IconButton
                className={styles.copyBtn}
                onClick={() => {
                  copyToClipboard(value)
                }}
              >
                <div
                  className={styles.copyBtnIcon}
                  style={{
                    backgroundImage: `url("${copy}")`,
                  }}
                />
              </IconButton>
            </div>
          </>
        )}

        <div className={styles.label}>
          {t('recipient')}
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <div className={styles.value}>
            {cardOwner}
          </div>
          <IconButton
            className={styles.copyBtn}
            onClick={() => {
              copyToClipboard(cardOwner)
            }}
          >
            <div
              className={styles.copyBtnIcon}
              style={{
                backgroundImage: `url("${copy}")`,
              }}
            />
          </IconButton>
        </div>

      </div>
    </div>
  )
}
