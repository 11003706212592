import {BankList} from '../../components/BankList/BankList'
import {useSelector} from 'react-redux'
import {CardForm} from '../../components/CardForm/CardForm'
import {CashDeliveryForm} from '../../components/CashDeliveryForm/CashDeliveryForm'
import {StatusBlock} from '../../components/StatusBlock/StatusBlock'
import {FatalError} from '../../components/FatalError/FatalError'
import React from 'react'
import {SberpayForm} from '../../components/SberpayForm/SberpayForm';
import {SberpayRedirect} from '../../components/SberpayForm/SberpayRedirect';
import {SberpayStatus} from '../../components/SberpayForm/SberpayStatus';

export default function FormPage() {
  const {
    selectedBank,
    selectedSourceBank,
    update,
    fatalError,
    skipBankSelect,
    isCashDelivery,
    params,
  } = useSelector(store => store.form)
  const selectedSourceBankEnabled = params?.is_source_bank_guess_enabled && params?.is_source_bank_guess_enabled.toLowerCase() === "true"
  const isSberpay = params?.sberpay && params?.sberpay.toLowerCase() === 'true'

  const sberpayRedirectRawData = localStorage.getItem(`sberpayRedirectData-${params.invoice_id}`)

  let sberpayRedirectData = null
  if(sberpayRedirectRawData) {
    sberpayRedirectData = JSON.parse(sberpayRedirectRawData)
  }

  return (
    <div style={{
      minHeight: '100vh',
      maxWidth: 432,
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      padding: 32,
      boxSizing: "border-box"
    }}>
      {/*display: 'flex', justifyContent: 'center', alignItems: 'center'*/}
      <div style={{flexGrow: 1}}>
        <style dangerouslySetInnerHTML={{
          __html: `
          body {
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          
          code {
            font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
              monospace;
          }
          
          .title.title {
            margin-left: 30px;
            position: relative;
          }
          
          .title::before {
            display: block;
            position: absolute;
            left: -30px;
          }
          
          .title1::before {
            content: '1.';
          }
          
          .title2::before {
            content: '2.';
          }
          
          .list > * {
            display: block;
            position: relative;
            padding-left: 20px;
          }
          
          .list > *:before {
            content: attr(data-number);
            position: absolute;
            left: 0;
          }
        `
        }}/>

        {fatalError ? (
          <FatalError/>
        ) : (
          <>
            {update.data ? (
              <StatusBlock/>
            ) : (
              <>
                {(isSberpay && sberpayRedirectData?.isFailToPay !== true) ? (
                  <>
                    {sberpayRedirectRawData ? (
                      <>
                        {!sberpayRedirectData.isFinished && (
                          <SberpayRedirect/>
                        )}
                        {sberpayRedirectData.isFinished && (
                          <SberpayStatus />
                        )}
                      </>
                    ) : (
                      <SberpayForm/>
                    )}
                  </>
                ) : (
                  <>
                    {isCashDelivery && (
                      <CashDeliveryForm/>
                    )}

                    {selectedSourceBankEnabled && !isCashDelivery && selectedSourceBank === null && !skipBankSelect && (
                      <BankList isSourceBanks={true}/>
                    )}

                    {((!selectedSourceBankEnabled && !isCashDelivery && selectedBank === null && !skipBankSelect)
                      || (selectedSourceBankEnabled && !isCashDelivery && selectedBank === null && selectedSourceBank !== null && !skipBankSelect)) && (
                      <BankList/>
                    )}

                    {!isCashDelivery && (selectedBank || skipBankSelect) && (
                      <CardForm/>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>

      {/*<PageFooter/>*/}
    </div>
  )
}
