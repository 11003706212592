import {useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {sleep} from '../../utils/sleep';
import {CircularProgress} from '@mui/material';

export function SberpayRedirect(props) {
  const {params} = useSelector(store => store.form)
  const sberpayRedirectRawData = localStorage.getItem(`sberpayRedirectData-${params.invoice_id}`)
  const sberpayRedirectData = JSON.parse(sberpayRedirectRawData)

  const links = sberpayRedirectData.links
  const currentLinkIndex = sberpayRedirectData.currentLinkIndex

  useEffect(() => {
    void async function () {
      if (links[currentLinkIndex]) {
        let found = false
        // const z = new URL(window.location.href)
        const deepLink = links[currentLinkIndex]

        window.focus()

        function onBlur(e) {
          // found = true
        }
        window.addEventListener("blur", onBlur);

        await sleep(25)

        setTimeout(() => {
          window.location = deepLink.link
        })

        await sleep(500)

        window.removeEventListener("blur", onBlur)

        if (found) {
          localStorage.setItem(`sberpayRedirectData-${params.invoice_id}`, JSON.stringify({
            ...sberpayRedirectData,
            isFinished: true,
            isFound: true,
            currentLinkIndex: currentLinkIndex,
          }))
        } else {
          localStorage.setItem(`sberpayRedirectData-${params.invoice_id}`, JSON.stringify({
            ...sberpayRedirectData,
            isFinished: false,
            currentLinkIndex: currentLinkIndex + 1,
          }))
        }
        window.location = window.location.href
      } else {
        localStorage.setItem(`sberpayRedirectData-${params.invoice_id}`, JSON.stringify({
          ...sberpayRedirectData,
          isFinished: true,
        }))
        window.location = window.location.href
      }
    }()
  }, [])

  return (
    <div>
      <div style={{flexGrow: 1, marginTop: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{
          flexGrow: 1,
          marginTop: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CircularProgress
            size={120}
            thickness={2}
          />
        </div>
      </div>
    </div>
  )
}
