import {useSelector} from 'react-redux'
import {AlertTitle, Box, Button, Card, CardActionArea, Typography} from '@mui/material'
import React, {useCallback, useEffect, useState, useLayoutEffect, useRef} from 'react'
import {dispatch} from '../../store/store'
import {formSlice} from '../../store/form/formSlice'
// import {LoadingButton} from '@mui/lab';

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {useTranslation} from "react-i18next";
import AndroidIcon from '../../assets/icons/android-icon.svg'
import iOSIcon from '../../assets/icons/ios-icon.svg'
import {getMobileOperatingSystem} from '../../utils/getMobileOperatingSystem'
import {mobileUtils} from '../../utils/mobileUtils';
import {QRCode} from '../QRCode/QRCode';
import {removeListener} from '@reduxjs/toolkit';
import {LoadingButton} from '@mui/lab';
import {getDeepLinks} from '../../config/deepLinks';
import {PageHeader} from '../PageHeader/PageHeader';
import {StatusBlock} from '../StatusBlock/StatusBlock';
import sberPayLogo from '../../assets/sberPayLogo.svg';

export function SberpayStatus(props) {
  const {t, i18n} = useTranslation('translation', {keyPrefix: 'SberPay'})
  const {t: ct} = useTranslation('translation', {keyPrefix: 'common'})

  const form = useSelector(store => store.form)
  const {
    params,
    skipBankSelect,
    selectedBank,
    formData,
    formTime,
  } = form
  const [isMobile] = useState(mobileUtils.isMobile())
  const [isDeepLinksProcessing, setIsDeepLinksProcessing] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [sberpayRedirectRawData] = useState(localStorage.getItem(`sberpayRedirectData-${params.invoice_id}`))
  const [sberpayRedirectData, setSberpayRedirectData] = useState(JSON.parse(sberpayRedirectRawData))

  const payConfirm = useCallback(() => {
    setIsLoading(true)
    localStorage.setItem(`sberpayRedirectData-${params.invoice_id}`, JSON.stringify({
      ...sberpayRedirectData,
      isConfirmed: true,
    }))
    setSberpayRedirectData({
      ...sberpayRedirectData,
      isConfirmed: true,
    })
    dispatch(formSlice.actions.update({status: 'paid'}))
  }, [sberpayRedirectData])

  const failToPay = useCallback(() => {
    setIsLoading(true)
    localStorage.setItem(`sberpayRedirectData-${params.invoice_id}`, JSON.stringify({
      ...sberpayRedirectData,
      isFailToPay: true,
    }))
    setSberpayRedirectData({
      ...sberpayRedirectData,
      isFailToPay: true,
    })
    window.location = window.location.href
  }, [sberpayRedirectData])

  const timeLimit = formData?.data?.ttl * 60 - (((Date.now() - formTime) / 1e3) | 0)
  const [time, setTime] = useState(timeLimit)
  const timeMin = String(Math.floor(Math.round(time) / 60)).padStart(2, '0')
  const timeSec = String(Math.round(time) % 60).padStart(2, '0')

  useEffect(() => {
    if (!timeLimit) {
      return
    }
    const started = Date.now()
    setTime(timeLimit - (Date.now() - started) / 1e3)
    const interval = setInterval(() => {
      setTime(timeLimit - (Date.now() - started) / 1e3)
    }, 1e3)
    return () => clearInterval(interval)
  }, [timeLimit])

  useEffect(() => {
    if (time <= 0) {
      dispatch(formSlice.actions.formTimeout())
    }
  }, [time, isMobile])

  const {banks} = form
  const widgetData = banks?.data?.payment_widget_data ?? {}
  let instructions = widgetData.instructions
  let instruction = makeHowToListMobile('sberPay')

  const startProcessing = useCallback(async () => {
    setIsDeepLinksProcessing(true)
    const {beneficiary} = formData?.data
    const links = getDeepLinks(
      mobileUtils.getMobileOS(),
      beneficiary.requisite_type,
      {
        ...beneficiary,
        amount: Number(params.amount)
      },
    )

    localStorage.setItem(`sberpayRedirectData-${params.invoice_id}`, JSON.stringify({
      invoice_id: params.invoice_id,
      isFinished: false,
      isFound: false,
      isConfirmed: false,
      isFailToPay: false,
      currentLinkIndex: 0,
      links,
    }))

    window.location = window.location.href
  }, [formData])

  function makeHowToListMobile(instruction) {
    if (instructions) {
      const texts = []
      let index = 0
      while (++index) {
        if (instructions[`${index}`]) {
          texts.push(instructions[`${index}`])
        }
        if (!instructions[`${index}`] && index) {
          break
        }
      }
      return texts
    }
    const texts = []
    let index = 0
    while (++index) {
      const key = `CardForm.paymentTips.${instruction}.${index.toString()}`
      const text = t(`paymentTips.${instruction}.${index.toString()}`)
      if (key === text) {
        break
      }
      texts.push(text)
    }
    return texts
  }

  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h5">
          {ct(`title.sberPay`)}
        </Typography>
      </div>

      <PageHeader/>

      <LoadingButton
        sx={{mb: 2.5, mt: 1.5}}
        className="sberPayBtn"
        loading={isDeepLinksProcessing}
        onClick={startProcessing}
      >
        <div style={{zIndex: 10, display: 'flex', gap: 12}}>
          <div
            style={{
              background: `url("${sberPayLogo}") no-repeat center center`,
              height: 24,
              width: 24,
              backgroundSize: 'contain',
            }}
          />
          <Typography style={{color: '#fff'}}>
            SberPay
          </Typography>
        </div>
      </LoadingButton>

      <Box mt={2} mb={2} style={{color: '#666666'}}>
        {t('remainingTime')}
        {' '}
        <Typography component="span" style={{fontSize: 14, color: '#000'}}>
          {timeMin}:{timeSec}
        </Typography>
      </Box>

      {instruction && (
        <>
          <AlertTitle mb={2}>
            {t('HowTo.title')}
          </AlertTitle>
          <div className={'list'} style={{marginTop: 16}}>
            {instruction.map((text, index) => (
              <span key={`key-${index}`} data-number={`${index + 1}.`}>
                {text}
              </span>
            ))}
          </div>
        </>
      )}

      <Button
        sx={{mb: 2.5, mt: 3}}
        fullWidth
        variant="contained"
        size="large"
        onClick={payConfirm}
        disabled={isLoading}
      >
        {t('paymentConfirmed')}
      </Button>

      <Button
        variant="outlined"
        fullWidth size="large"
        onClick={failToPay}
        disabled={isLoading}
      >
        {t('failedToPay')}
      </Button>
    </div>
  )
}
